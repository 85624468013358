import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import "../assets/css/global.css"
import Instagram from "../assets/images/instagram.svg"
import Facebook from "../assets/images/facebook.svg"
import LinkedIn from "../assets/images/linkedin.svg"
import Twitter from "../assets/images/twitter.svg"
import Website from "../assets/images/external-svgrepo-com.svg"

const JudgesList = ({ judges = [] }) => {
  return (
    <div>
      {judges.map(judge => {
        const { id, name, profilePicture, occupation, universityOrCollege, bio, instagramLink, facebookLink, linkedinLink, twitterLink, websiteLink } = judge
        return (
            <div key={id} className="container pb-50">
              <div className="grid-cols-4 grid-cols-1" style={{alignItems: "center"}}>
                <div className="col-span-1">
                  <GatsbyImage
                  image={profilePicture.gatsbyImageData}
                  className="margin-auto"
                  alt={name}
                  maxWidth="800px"
                  />
                </div>
                <div className="col-span-3 margin-auto">
                  <h2>{name}</h2>
                  <p style={{fontFamily: "Proxima Nova, sans-serif", fontWeight: "400", color: "#666666", paddingBottom: "1rem"}} className="pt-10">{occupation} | {universityOrCollege}</p>
                  <div className="icon-center">
                    {(instagramLink) ? <a href={ instagramLink } target="_blank" rel="noreferrer">
                      <img
                        src={Instagram}
                        alt="Instagram icon"
                        className="hero-img"
                        placeholder="tracedSVG"
                        style={{maxWidth: "10%", display: "inline-block", fontSize: "10px", paddingBottom: "15px", paddingRight: "15px"}}
                      />
                    </a> : null}
                    {(facebookLink) ? <a href={ facebookLink } target="_blank" rel="noreferrer">
                      <img
                        src={Facebook}
                        alt="Facebook icon"
                        className="hero-img"
                        placeholder="tracedSVG"
                        style={{maxWidth: "10%", display: "inline-block", fontSize: "10px", paddingBottom: "15px", paddingRight: "15px"}}
                      />
                    </a> : null}
                    {(linkedinLink) ? <a href={ linkedinLink } target="_blank" rel="noreferrer">
                      <img
                        src={LinkedIn}
                        alt="LinkedIn icon"
                        className="hero-img"
                        placeholder="tracedSVG"
                        style={{maxWidth: "10%", display: "inline-block", fontSize: "10px", paddingBottom: "15px", paddingRight: "15px"}}
                      />
                    </a> : null}
                    {(twitterLink) ? <a href={ twitterLink } target="_blank" rel="noreferrer">
                      <img
                        src={Twitter}
                        alt="Twitter icon"
                        className="hero-img"
                        placeholder="tracedSVG"
                        style={{maxWidth: "10%", display: "inline-block", fontSize: "10px", paddingBottom: "15px"}}
                      />
                    </a> : null}
                    {(websiteLink) ? <a href={ websiteLink } target="_blank" rel="noreferrer">
                      <img
                        src={Website}
                        alt="Website icon"
                        className="hero-img"
                        placeholder="tracedSVG"
                        style={{maxWidth: "7%", display: "inline-block", fontSize: "5px", paddingBottom: "15px"}}
                      />
                    </a> : null}
                  </div>
                </div>
              </div>
                <p className="margin-auto">{bio}</p>
            </div>
        )
      })}
    </div>
  )
}

export default JudgesList