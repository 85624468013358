import React from "react"
import JudgesList from "./JudgesList"
import { graphql, useStaticQuery } from "gatsby"
import "../assets/css/global.css"

const query = graphql`
  {
    allDatoCmsJudge(sort: {fields: name}, filter: {active: {eq: false}}) {
        nodes {
          id
          name
          profilePicture {
            gatsbyImageData(placeholder: TRACED_SVG, layout: FIXED)
          }
          occupation
          universityOrCollege
          bio
          instagramLink
          facebookLink
          linkedinLink
          twitterLink
          websiteLink
        }
      }
  }
`

const AllJudges = () => {
  const data = useStaticQuery(query)
  const judges = data.allDatoCmsJudge.nodes
  return (
    <section className="container-lg">
      <JudgesList judges={judges} />
    </section>
  )
}

export default AllJudges